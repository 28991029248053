import React, { useState } from "react";
import "./list.scss"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navbar"
import Datatable from "../../components/Datatable/Datatable"
import axios from "axios";
import { getHostUrl } from "../../config";

function Quote  (){
    const [user,setUser] =  useState([])

    React.useEffect(()=>{
      getSubscriber()
    },[])
  
    const getSubscriber = (e)=> {
        const formData ={
            type:'quote'
        }
      axios.post(getHostUrl('/api/user/getMailUsList'), formData, {
      }).then((response)=>{
        if (response.status ==200 && response.data.success){
          setUser(response.data.enquiry)
        }else{
          alert("Something went wrong.")
      }
      })
    }
   const userColumns = [
    { field: "name", headerName: "Name", width: 140 },
    {
      field: "email",
      headerName: "Email",
      width: 220,
    },
    {
      field: "phonenumber",
      headerName: "Phone Number",
      width: 180,
    },
    {
      field: "organisation",
      headerName: "Organisation",
      width: 150,
    },
    {
      field: "country",
      headerName: "Country",
      width: 120
    },
    {
      field: "subscribe_at",
      headerName: "Timestamp",
      width: 160
    }
  ];
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatable row={user} col={userColumns}/>
      </div>
    </div>
  )
}

export default Quote
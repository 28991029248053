

export function getHostUrl(uri) {
   let host =
     window.location.host === "www.testdel.com" ||
     window.location.host === "testdel.com"
       ? "https://testdel.com"
       : "https://testdel.com";
   return `${host}${uri}`
};

 

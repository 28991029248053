import React, { Component, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios  from 'react';
import RecentPost from './RecentPost';
import { Linkedin } from 'react-social-sharing'
import {ShareSocial} from 'react-share-social' 
import { getHostUrl } from "../config";
import { useLocation, useSearchParams,useParams } from "react-router-dom";

const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'

const Loader = () => <div></div>;
const Title="Who We Are";
const Description="";

function SinglePost (props){
    const location = useLocation();
    const params = useParams()
    const[products,setProducts] = useState([])
    const[shareUrl,setShareUrl] = useState(`https://${window.location.hostname}${location.pathname}`,)
    const[loading,setLoading] = useState(false)
    const [allPost, setAllPost] = useState(location?.state?.allPost);


      React.useEffect(() => {
        let productObject = mapProduct(location?.state?.product);
        setAllPost(location?.state?.allPost);
        setProducts([productObject]);
      }, [location?.state?.product]);

         const mapProduct =(product)=>{
            return {
              id: product.id,
              slug: product.slug,
              image: product?.better_featured_image?.media_details?.file,
              name: product.title.rendered,
              content: product.content.rendered,
              description: product.excerpt.rendered
            }
          }

      const style = {
        root: {
          background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          borderRadius: 10,
          border: 0,
          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
          color: 'white',
          padding:15
      
        },
        container:{
          height:'100px',
          padding:15
        },
        copyContainer: {
          border: '1px solid',
          background: 'rgb(0,0,0,0.7)',
        },
        title: {
          color: 'aquamarine',
          fontStyle: 'italic',

        }
      };

      
         return (
           <div>
             <div className="page-title-area page-title-bg1">
               <div className="d-table">
                 <div className="d-table-cell">
                   <div className="container">
                     {products.map((product) => (
                       <div className="page-title-content">
                         <Helmet>
                           {/* HTML Meta Tags  */}
                           <title>{product.name} </title>
                           <meta
                             name="title"
                             content={product.name}
                             data-react-helmet="true"
                           />
                           <meta
                             name="description"
                             content={product.description}
                             data-react-helmet="true"
                           />

                           {/* Facebook Meta Tags */}
                           <meta
                             property="og:url"
                             content={getHostUrl("")}
                             data-react-helmet="true"
                           />
                           <meta
                             property="og:type"
                             content="website"
                             data-react-helmet="true"
                           />
                           <meta
                             property="og:title"
                             content={product.name}
                             data-react-helmet="true"
                           />
                           <meta
                             property="og:description"
                             content={product.description}
                             data-react-helmet="true"
                           />
                           <meta
                             property="og:image"
                             content={
                               "https://wpblog.testdel.com/wp-content/uploads/" +
                               product.image +
                               "?w=1200&h=630&scale.option=fit"
                             }
                             data-react-helmet="true"
                           />

                           {/* Twitter Meta Tags  */}
                           <meta
                             name="twitter:card"
                             content="summary_large_image"
                             data-react-helmet="true"
                           />
                           <meta
                             property="twitter:domain"
                             content={window.location.hostname}
                             data-react-helmet="true"
                           />
                           <meta
                             property="twitter:url"
                             content={getHostUrl("")}
                             data-react-helmet="true"
                           />
                           <meta
                             name="twitter:title"
                             content={product.name}
                             data-react-helmet="true"
                           />
                           <meta
                             name="twitter:description"
                             content={product.description}
                             data-react-helmet="true"
                           />
                           <meta
                             name="twitter:image"
                             content={
                               "https://wpblog.testdel.com/wp-content/uploads/" +
                               product.image +
                               "?w=1200&h=630&scale.option=fit"
                             }
                             data-react-helmet="true"
                           />
                         </Helmet>
                         <h2>{product.name}</h2>
                         <ul>
                           <li>
                             <a href="/">Home</a>
                           </li>
                           <li>
                             <a href="/blogs">Blog</a>
                           </li>
                           <li>{product.name}</li>
                         </ul>
                       </div>
                     ))}
                   </div>
                 </div>
               </div>
               <div className="shape-img2">
                 <img src={`../assets/img/shape/2.svg`} alt="image" />
               </div>
               <div className="shape-img3">
                 <img src={`../assets/img/shape/3.svg`} alt="image" />
               </div>
               <div className="shape-img4">
                 <img src={`../assets/img/shape/4.png`} alt="image" />
               </div>
               <div className="shape-img5">
                 <img src={`../assets/img/shape/5.png`} alt="image" />
               </div>
               <div className="shape-img7">
                 <img src={`../assets/img/shape/7.png`} alt="image" />
               </div>
               <div className="shape-img8">
                 <img src={`../assets/img/shape/8.png`} alt="image" />
               </div>
               <div className="shape-img9">
                 <img src={`../assets/img/shape/9.png`} alt="image" />
               </div>
               <div className="shape-img10">
                 <img src={`../assets/img/shape/10.png`} alt="image" />
               </div>
             </div>
             {/* End Page Title Area */}
             {/* Start Blog Details Area */}
             <section className="blog-details-area single-blog ptb-100">
               <div className="container">
                 <h2 className Style="text-align:center !important;">
                   {loading ? <Loader /> : null}
                 </h2>

                 <div className="row">
                   <div className="col-lg-8 col-md-12">
                     {products.map((product) => (
                       <div className="blog-details-desc">
                         <div className="article-image">
                           <img
                             src={
                               "https://wpblog.testdel.com/wp-content/uploads/" +
                               product.image
                             }
                             alt="image"
                           />
                         </div>
                         <div className="article-content">
                           <div className="entry-meta">
                             <ul>
                               <li>
                                 <span>Posted By:</span> <a href="#">TestDel</a>
                               </li>
                             </ul>
                           </div>
                           <h3>{product.name}</h3>
                           <div
                             className="blog-single"
                             dangerouslySetInnerHTML={{
                               __html: product.content.replace(/\n/g, "<br />"),
                             }}
                           />
                         </div>
                       </div>
                     ))}
                   </div>

                   <div className="col-lg-4 col-md-12">
                     <aside className="widget-area" id="secondary">
                       <ShareSocial
                         url={shareUrl}
                         socialTypes={[
                           "facebook",
                           "twitter",
                           "whatsapp",
                           "linkedin",
                         ]}
                         onSocialButtonClicked={(data) => console.log(data)}
                         style={style}
                       />
                       <section style={{flexWrap:'wrap',height:'unset'}} className="widget widget_recent_entries">
                         <h3 className="widget-title">Recent Posts</h3>
                         {allPost ?
                         <RecentPost allPost={allPost} />:null}
                       </section>
                     </aside>
                   </div>
                   
                 </div>
               </div>
             </section>
             {/* End Blog Details Area */}
           </div>
         );



 
}
 
 
export default SinglePost;
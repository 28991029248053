import React from "react";
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

const Datatable = ({ col, row }) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        let param = params.row;
        return (
          <div className="cellAction">
            <Link
              state={param}
              to={{
                pathname: "/users/test",
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View Message</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <DataGrid
        disableColumnMenu={true}
        getRowId={(value) => value._id}
        className="datagrid"
        rows={row}
        columns={col.concat(actionColumn)}
        pageSize={8}
        rowsPerPageOptions={[8]}
      />
    </div>
  );
};

export default Datatable;

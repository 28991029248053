import React from "react";
import "./single.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import List from "../../components/Table/Table";
import Message from "../../components/Message/Message";
import { useLocation } from "react-router";

const Single = ({}) => {
  const location = useLocation();
  const info = location.state; // Access the state passed via Link
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle">{info.firstname} {info.lastname}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{info.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{info.phonenumber}</span>
                </div>
                {info.organisation?
                <div className="detailItem">
                  <span className="itemKey">Organisation:</span>
                  <span className="itemValue">{info.organisation}</span>
                </div>:null}
                {info.project?
                <div className="detailItem">
                  <span className="itemKey">Project:</span>
                  <span className="itemValue">{info.project}</span>
                </div>:null}
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{info.country}</span>
                </div>

              </div>
            </div>
          </div>
          <div className="right">
            <Message aspect={3 / 1} title="User Message" message={info.message}/>
          </div>
        </div>
        {/* <div className="bottom">
        <h1 className="title">Last Transactions</h1>
          <List/>
        </div> */}
      </div>
    </div>
  );
};

export default Single;

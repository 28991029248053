import React from 'react';
import Axios  from 'axios';
import countries from '../components/Countries.json'
import { findIndexByKeyValue, ValidateBussinessEmail } from '../utils/functions';
import { getHostUrl } from '../config';
const Title="Get A Free Quote";
const Description="";
class RequestQuote extends React.Component  {
  
  
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname:'',
      organisation:'',
      email: '',
      number:'',
      project:'',
      file:'',
      msg:'',
      countryName: '',
        countryCode: '',
      isSubmitted: false,
      errors:{
        first:'',
        last:'',
        number:'',
        org:'',
        msg:'',
      },
    }
    }


  getGeoInfo = async () => {
    try {
      // Step 1: Get the user's public IP address using ipify
      const ipResponse = await Axios.get("https://api.ipify.org?format=json");
      const userIP = ipResponse.data.ip;
  
      // Step 2: Fetch geolocation details using freegeoip.app
      const geoResponse = await Axios.get(`https://freegeoip.app/json/${userIP}`);
      const countryCode = geoResponse.data.country_code;
      let key = findIndexByKeyValue(countries,'code',countryCode)

      this.setState({
        countryName: key.name,
        countryCode: key.dial_code,
    });
    } catch (error) {
      console.error("Error fetching geolocation info:", error);
    }
  };

  componentDidMount(){

    this.getGeoInfo();
}
render() {
  
  return (
    <div>
    
    <div className="analysdis-form">
              <div >
                  <div className="row">
                     <div className="form-group col-6">
                        <input type="text" className="form-control" required value={this.state.firstname} onChange={this.onFNameChange.bind(this)} placeholder="First Name" />
                        <small className="text-danger"> {this.state.errors.first}</small>
                      </div>
                      <div className="form-group col-6">
                        <input type="text" className="form-control" required value={this.state.lastname} onChange={this.onLNameChange.bind(this)} placeholder="Last Name" />
                        <small className="text-danger"> {this.state.errors.last}</small>
                      </div>
                    <div className="col-lg-12">
                    
                      <div className="form-group">
                        <input type="email" className="form-control" required value={this.state.email} onChange={this.onEmailChange.bind(this)} placeholder="Business Email" />
                        <small className="text-danger"> {this.state.errors.email}</small>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" required value={this.state.organisation} onChange={this.onOrganisationChange.bind(this)} placeholder="Company Name" />
                        <small className="text-danger"> {this.state.errors.org}</small>

                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" required value={this.state.number} onChange={this.onNumberChange.bind(this)}  placeholder="Phone Number" />
                        <small className="text-danger"> {this.state.errors.number}</small>
                      </div>
                     
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                      <textarea className="form-control" value={this.state.msg} required onChange={this.onMsgChange.bind(this)} placeholder="Please inform us about testing types and anything else you consider important"></textarea>
                      <small className="text-danger"> {this.state.errors.msg}</small>
                        </div>
                    </div>
                  </div>
                 
                      <div className="form_policy text-white">
                        TestDel does not sell, rent or otherwise make available any personal data submitted by visitors to our site to any third parties. By submitting this form, I agree to TestDel's Privacy Policy.
                </div>
                  <div className="text-center">
                    <button type="submit" className="default-btn col-md-4" value="Send" onClick={this.handleSubmit.bind(this)}>
                      Send 
                      <span />
                    </button>
                  </div>
                </div>
                <br/>
                {this.state.isSubmitted && <div id="msgSubmit" class="alert alert-success text-center">Thank you we have received your email and will get back to you as soon as possible.</div>}
         
              </div>
  
</div>

  );
}




  
onFNameChange(event) {
  let errors = this.state.errors;
  if (event.target.value.match('^[a-zA-Z ]*$') != null) {
    errors.first = '';
    this.setState({ firstname: event.target.value, errors: errors });
  } else {
    errors.first = 'Enter Only Character';
    this.setState({ errors });
  }
}
onLNameChange(event) {
  let errors = this.state.errors;
  if (event.target.value.match('^[a-zA-Z ]*$') != null) {
    errors.last = '';
    this.setState({ lastname: event.target.value, errors: errors });
  } else {
    errors.last = 'Enter Only Character';
    this.setState({ errors });
  }
}

onEmailChange(event) {
  let errors = this.state.errors;
  errors.email = ValidateBussinessEmail(event.target.value)
    ? ''
    : 'Please enter your business email address';
  this.setState({
    email: event.target.value,
    errors,
  });
}

onOrganisationChange(event) {
  let errors = this.state.errors;
  if (!event.target.value) {
    errors.org = 'Company name is mandatory';
  } else {
    errors.org = '';
  }
  this.setState({ organisation: event.target.value, errors });
}
  
onNumberChange(event) {
  const re = /^[0-9\b]+$/;
  const number = event.target.value;
  let errors = this.state.errors;
  if (event.target.value === '') {
    errors.number = '';
    this.setState({ number: event.target.value, errors: errors });
  }
  if (event.target.value === '' || re.test(event.target.value)) {
    errors.number = '';
    this.setState({ number: event.target.value, errors: errors });
  } else {
    errors.number = 'Enter Only Integer Number';
    this.setState({ errors });
  }

  if (event.target.value.length > 0) {
    if(event.target.value.length >= 10){
      errors.number = '';
      this.setState({ errors });
      this.setState({ number: event.target.value, errors: errors });
    }else {
    errors.number = 'Enter Min 10 Digit Number';
    this.setState({ errors: errors });
  }
}
}
onMsgChange(event) {
  let errors = this.state.errors;
  if (event.target.value) {
    errors.msg = '';
    this.setState({ msg: event.target.value, errors: errors });
  } else {
    errors.msg = 'Please enter at least 15 characters.';
    this.setState({ errors: errors, msg: event.target.value });
  }
}

  resetForm(){
    this.setState({firstname:'',lastname:'', email: '',organisation:'',number:'',msg:'',file:'',errors:{
      first:'',
      last:'',
      number:'',
      org:'',
      msg:'',
    },})

 }

 validateForm = () => {
  let valid = true;
  let errors = this.state.errors;
  if (!this.state.firstname) {
    valid = false;
    errors.first = 'First name is mandatory';
    this.setState({
      errors,
    });
  }
  if (!this.state.lastname) {
    valid = false;
    errors.last = 'Last name is mandatory';
    this.setState({
      errors,
    });
  }
  if (!this.state.email) {
    valid = false;
    errors.email = 'Email is mandatory';
    this.setState({
      errors,
    });
  } else {
    if (!ValidateBussinessEmail(this.state.email)) {
      valid = false;
      errors.email = 'Please valid business email address';
      this.setState({
        errors,
      });
    }
  }
  if (!this.state.msg) {
    valid = false;
    errors.msg = 'Message is mandatory';
    this.setState({
      errors,
    });
  } else if (this.state.msg && this.state.msg.length <= 15) {
    valid = false;
    errors.msg = 'Please enter at least 15 characters.';
    this.setState({
      errors,
    });
  }
  if (!this.state.organisation) {
    valid = false;
    errors.org = 'Company name is mandatory';
    this.setState({
      errors,
    });
  }
  return valid;
};

handleSubmit(e) {
if(this.validateForm()){
e.preventDefault();
const formData = new FormData();
formData.append('firstname', this.state.firstname);
formData.append('lastname', this.state.lastname);
formData.append('email', this.state.email);
formData.append('number', this.state.number);
formData.append('organisation', this.state.organisation);
formData.append('project', '-');
formData.append('msg', this.state.msg);
formData.append('countryname', this.state.countryName);
formData.append('countrycode', this.state.countryCode);
formData.append('enquiry_type', 'automation');
let country = localStorage.getItem("country_code");
formData.append("country", country);

  Axios.post(getHostUrl('/api/user/sendquote'), formData, { // receive two parameter endpoint url ,form data 
  }).then((response)=>{
  console.log(response.success);
 
  if (response.status ==200){
    this.setState({isSubmitted: true});
      this.resetForm();
  }else{
    alert("Something went wrong.")
  }
  })
}
}





}

export default RequestQuote;

import React from "react";
import { BrowserRouter as Router, Route, Link, useLocation } from "react-router-dom";
import Logo from "../logowhite.png";
import { sendConversionEvent } from "../utils/functions";
const Header = () => {


  const location = useLocation(); // Get the current location of the route

  React.useEffect(() => {
    console.log('sendConversionEvent')
    // Trigger the conversion event every time the route changes
    // sendConversionEvent('AW-16794045238/lcLjCO2Av-4ZELaWg8g-', 1.0, 'GBP'); 
  }, [location]); // Only re-run the effect when the route changes


  return (
    <div>
      {/* Start Navbar Area */}
      <div className="navbar-area">
        <div className="aronix-responsive-nav">
          <div className="container">
            <div className="aronix-responsive-menu mean-container">
              <div className="mean-bar">
                <div className="burger-menu">
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <div className="logo">
                <a href="/">
                  <img src={Logo} alt="logo" width="90" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="aronix-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="/">
                <img src={Logo} alt="logo" width="110" />
              </a>

              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href={"/who-we-are"} className="nav-link">
                      About Us <i className="fas fa-chevron-down" />{" "}
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item" style={{ width: "100%" }}>
                        <a
                          href={`${process.env.PUBLIC_URL + "/who-we-are"}`}
                          className="nav-link"
                        >
                          Our story{" "}
                        </a>
                      </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <a
                          href={`${process.env.PUBLIC_URL + "/our-lab"}`}
                          className="nav-link"
                        >
                          Our lab{" "}
                        </a>
                      </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                      <a
                        href={`${process.env.PUBLIC_URL + "/how-it-work"}`}
                        className="nav-link"
                      >
                        How It Work{" "}
                      </a>
                    </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <a
                          href={`${process.env.PUBLIC_URL + "/business-model"}`}
                          className="nav-link"
                        >
                          Business Model
                        </a>
                      </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <a
                          href={`${process.env.PUBLIC_URL + "/case-study"}`}
                          className="nav-link"
                        >
                          Case Study
                        </a>
                      </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <a
                          href={`${process.env.PUBLIC_URL + "/methodology"}`}
                          className="nav-link"
                        >
                          Methodology
                        </a>
                      </li>
                      <li className="nav-item" style={{ width: "100%" }}>
                        <a
                          href={`${process.env.PUBLIC_URL + "/technologies"}`}
                          className="nav-link"
                        >
                          Technologies
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="/what-we-offer" className="nav-link">
                      What We Offer <i className="fas fa-chevron-down" />
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a
                          href="/managed-testing-services"
                          className="nav-link"
                        >
                          Managed Testing Service
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/test-environment-management"
                          className="nav-link"
                        >
                          Test Environment Management
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/test-automation-services"
                          className="nav-link"
                        >
                          Test Automation Services
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/qa-consulting" className="nav-link">
                          QA Consulting Service
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/testing-documentation" className="nav-link">
                          Test Documentation
                        </a>
                      </li>

                      <li className="nav-item">
                        <a href="/security-testing" className="nav-link">
                          Security Testing
                        </a>
                      </li>
                      
                      <li className="nav-item">
                        <a href="/functional-testing" className="nav-link">
                          Functional Testing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/non-functional-testing" className="nav-link">
                          Non-Functional Testing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/robotic-process-automation"
                          className="nav-link"
                        >
                          RPA Consultancy
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/services/software-testing-services"
                      className="nav-link"
                    >
                      Services <i className="fas fa-chevron-down" />
                    </a>
                    <ul className="dropdown-menu mega-menu">
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a
                          href="/web-applications-testing"
                          className="nav-link"
                        >
                          Web Applications Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a
                          href="/mobile-applications-testing"
                          className="nav-link"
                        >
                          Mobile Applications Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/website-testing" className="nav-link">
                          Website Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a
                          href="/desktop-applications-testing"
                          className="nav-link"
                        >
                          Desktop Application Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/accessibility-testing" className="nav-link">
                          Accessibility Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/big-data-testing" className="nav-link">
                          Big Data Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/blockchain-testing" className="nav-link">
                          Blockchain Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/crm-erp-testing" className="nav-link">
                          CRM & ERP Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/sap-testing" className="nav-link">
                          Sap Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/elt-testing" className="nav-link">
                          ETL Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/cloud-solutions-testing" className="nav-link">
                          Cloud Solutions Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a
                          href="/internet-of-things-testing"
                          className="nav-link"
                        >
                          Internet of Things Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/service-now-testing" className="nav-link">
                          Service Now Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/workday-testing" className="nav-link">
                          Workday Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/performance-testing" className="nav-link">
                          Performance Testing
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        style={{ maxWidth: "250px", width: "100%" }}
                      >
                        <a href="/games-testing" className="nav-link">
                          Games Testing
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <a href="/blogs" className="nav-link">
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/contact-us" className="nav-link">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/join-the-team" className=" btn nav-link">
                      Join The Team
                    </a>
                  </li>
                  <li className="nav-item mobile-quote">
                    <a href="/get-a-free-quote" className=" btn nav-link">
                      Get A Free Quote
                    </a>
                  </li>
                </ul>
                <div className="others-options">
                  <div className="option-item">
                    <a href="/get-a-free-quote" className=" btn quote-btn">
                      Get A Free Quote
                    </a>
                  </div>
                  <div className="burger-menu">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* End Navbar Area */}
      {/* Sidebar Modal */}
      <div className="sidebar-modal">
        <div className="sidebar-modal-inner">
          <div className="sidebar-about-area">
            <div className="title">
              <h2>About Us</h2>
              <p>
                TestDel is a leading quality assurance specialist with over 5
                years of experience in providing all testing related services to
                ensure, successful delivery of programmes. Our main focus is to
                provide end-to-end quality assurance services around the world
                to Software Development firms and end clients. Our test teams
                work with organizations across all industries to meet all
                testing challenges effectively.
              </p>
            </div>
          </div>

          <div className="sidebar-contact-area">
            <div className="contact-info">
              <div className="contact-info-content">
                <h2>
                  <div
                    style={{
                      display: "table",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <div className="row">
                      <label
                        style={{
                          backgroundColor: "#d7dade",
                          paddingTop: 6,
                          paddingBottom: 2,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 5,
                          textAlign: "center",
                          verticalAlign: "center",
                          fontSize: 14,
                        }}
                      >
                        UK
                      </label>
                      &nbsp;&nbsp;
                      <a href="tel:+442079936054">+442079936054</a>
                    </div>
                    <div className="row">
                      <label
                        style={{
                          backgroundColor: "#d7dade",
                          paddingTop: 6,
                          paddingBottom: 2,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 5,
                          textAlign: "center",
                          verticalAlign: "center",
                          fontSize: 14,
                        }}
                      >
                        US
                      </label>
                      &nbsp;&nbsp;
                      <a href="tel:+14252006001">+14252006001</a>
                    </div>
                  </div>
                  <span>OR</span>
                  <a href="mailto:aronix@gmail.com">team@testdel.com</a>
                </h2>
                <ul className="social">
                  <li>
                    <a href="https://twitter.com/testdelgroup" target="_blank">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/testdelgroup"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/testdel/about"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/testdelgroup"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span className="close-btn sidebar-modal-close-btn">
            <i className="flaticon-close" />
          </span>
        </div>
      </div>
      {/* End Sidebar Modal */}
    </div>
  );
};

export default Header;


import React from "react";
import Axios  from 'axios';
import ContactForm from "../components/ContactForm";
import MetaTags from '../components/MetaTags';
import countries from '../components/Countries.json'
import Select from 'react-select';
import {getHostUrl} from '../config';

import { findIndexByKeyValue, ValidateBussinessEmail } from "../utils/functions";

const imageFormat= localStorage.getItem('avifSupported') === 'YES' ? 'avif' : 'png'
const Title="Contact us";
const Description =
  "TestDel is an independent software testing and QA services company with expertise in testing Web applications, Mobile apps, and Desktop applications.";
class  ContactUs extends React.Component  {
    
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      number:'',
      file:'',
      msg:'',
      countryName: '',
      countryCode: '',
      isSubmitted: false,
      errors:{
        name:'',
        number:'',
        msg:'',
        countryName:'',
        countryCode:'',
      },
      selectedOption: null,
    }
    }


  getGeoInfo = async () => {
    try {
      // Step 1: Get the user's public IP address using ipify
      const ipResponse = await Axios.get("https://api.ipify.org?format=json");
      const userIP = ipResponse.data.ip;
  
      // Step 2: Fetch geolocation details using freegeoip.app
      const geoResponse = await Axios.get(`https://freegeoip.app/json/${userIP}`);
      const countryCode = geoResponse.data.country_code;
      let key = findIndexByKeyValue(countries,'code',countryCode)

      this.setState({
        countryName: key.name,
        countryCode: key.dial_code,
        selectedOption:key
    });
    } catch (error) {
      console.error("Error fetching geolocation info:", error);
    }
  };
  

  componentDidMount(){
    this.getGeoInfo();
  }     
  
  handleChange = (selectedOption) => {
    this.setState({ 
      selectedOption:selectedOption,
      countryCode:selectedOption.code,
      countryName:selectedOption.name
     })
  }
render() {


  return (
    <div>
        <MetaTags title={Title} description={Description}/>
      {/* Start Page Title Area */}
      <div className="page-title-area page-title-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 className="text-white">Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-img2">
          <img src={`assets/img/shape/2.svg`}  alt="image" />
        </div>
        <div className="shape-img3">
          <img src={`assets/img/shape/3.svg`}  alt="image" />
        </div>
        <div className="shape-img4">
          <img src={`assets/img/shape/4.png`}  alt="image" />
        </div>
        <div className="shape-img5">
          <img src={`assets/img/shape/5.png`}  alt="image" />
        </div>
        <div className="shape-img7">
          <img src={`assets/img/shape/7.png`}  alt="image" />
        </div>
        <div className="shape-img8">
          <img src={`assets/img/shape/8.png`}  alt="image" />
        </div>
        <div className="shape-img9">
          <img src={`assets/img/shape/9.png`}  alt="image" />
        </div>
        <div className="shape-img10">
          <img src={`assets/img/shape/10.png`}  alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <div className="container pt-5 pb-5">
        <div className="row row-column">
          <div className="col-lg-3 col-md-6 pt-3">
            <div className="info">
              <p>
                <strong>Email:</strong>
                <a href="mailto:team@testdel.com">team@testdel.com</a>
              </p>
              <p>
                <strong>
                  TestDel UK
                </strong>
                <br />
                21 Woodfield Road, Hounslow,
                <br />Middlesex TW4 6LL, UK
                <br /> <strong>Phone:</strong> +44 2079936054
                {/* <br />{" "}
                <span style={{ color: "#0000ff" }}>
                  <a
                    style={{ color: "#0000ff" }}
                    href="https://www.google.com/maps/place/2-4+Canning+Rd,+Harrow+HA3+7SJ,+UK/@51.5946299,-0.334745,17z/data=!3m1!4b1!4m5!3m4!1s0x4876137ec1b3cf1d:0xd23f58d4c1ae41fb!8m2!3d51.5946299!4d-0.334745"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View in Google Map
                  </a>
                </span> */}
              </p>
              <p>
                <strong>
                  TestDel USA
                </strong>
                <br /> 27181 SE 27TH ST, SAMMAMISH

                <br />WA 98075 USA 
                <br /> <strong>Phone:</strong> +1 425-200-6001
                {/* <br />{" "}
                <span style={{ color: "#0000ff" }}>
                  <a
                    style={{ color: "#0000ff" }}
                    href="https://www.google.com/maps/place/27181+SE+27th+St,+Sammamish,+WA+98075,+USA/@47.5856155,-121.9751969,17z/data=!3m1!4b1!4m5!3m4!1s0x5490705022d2ca1b:0x3052614a35c0e671!8m2!3d47.5856119!4d-121.9730029?shorturl=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View in Google Map
                  </a>
                </span> */}
              </p>              
              <p>
                <strong>TestDel India</strong>
                <br /> SCO 146-147, Basement,
                <br /> Sector 34, Chandigarh, 160022, India
                <br /> <strong>Phone:</strong> +91 7291014242
                {/* <br />{" "}
                <span style={{ color: "#0000ff" }}>
                  <a
                    style={{ color: "#0000ff" }}
                    href="https://www.google.com/maps/place/TestDel+India/@30.7201564,76.7633369,18z/data=!4m12!1m6!3m5!1s0x390fedd7d745d65b:0xad975ab078983082!2sTestDel+India!8m2!3d30.720055!4d76.7656061!3m4!1s0x390fedd7d745d65b:0xad975ab078983082!8m2!3d30.720055!4d76.7656061"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View in Google Map
                  </a>
                </span> */}
              </p>
            </div>
          </div>
          <div className="col-lg-9 col-md-6">
          <div className="analysis-form">
                <div className="section-title text-left">
                  <span className="sub-title">Get In Touch</span>
                  <h2 className="m-0">Take The First Step</h2>
                  <p>Let’s talk about how TestDel can associate with you</p>
                </div>
                <div >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)}  placeholder="Your Name" required />
                        <small className="text-danger"> {this.state.errors.name}</small>
                      </div>
                      <div className="form-group">
                        <input type="email" className="form-control"value={this.state.email} onChange={this.onEmailChange.bind(this)} placeholder="Bussiness Email"  required />
                        <small className="text-danger"> {this.state.errors.email}</small>
                      </div>
                      <div className="row country-row">
                      <div className="form-group coutry-picker">
                      <Select   
                      value={this.state.selectedOption} 
                      placeholder="Country"  
                      theme={(theme) => ({
                        ...theme,
                        borderWidth:1,
                        colors: {
                        ...theme.colors,
                          text: '#9d9fad',
                          primary25: '#9d9fad',
                          primary75: '#9d9fad',
                          primary: 'blue',
                          primary50: '#9d9fad',
                        },
                      })}
                      onChange={this.handleChange}
                      getOptionLabel={(data)=>data.dial_code}
                      getOptionValue={(data)=>data.code}
                      options={countries} >
                   </Select>
                       </div>
                       <div className="form-group phone-picker">
                      <input type="text" inputProps={
                      {maxLength: 12}
                      } className="form-control phone-input" value={this.state.number} onChange={this.onNumberChange.bind(this)} placeholder="Phone Number" required/>
                      <small className="text-danger"> {this.state.errors.number}</small>
                      </div>
                      </div>

                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                      <textarea value={this.state.msg} required onChange={this.onMsgChange.bind(this)} placeholder="Your Messege" className="form-control" >Your Messege</textarea>
                      <small className="text-danger"> {this.state.errors.msg}</small>
                    
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                        <input type="file" onChange={this.onFileChange.bind(this)} />
                        <small className="text-danger"> {this.state.isError4}</small>
                        <p> <small>{"(pdf, docx file types less than 4 MB)"}</small></p>
                      </div> */}
                      <div className="form_policy">TestDel does not sell, rent or otherwise make available any personal data submitted by visitors to our site to any third parties. By submitting this form, I agree to TestDel's <a href='privacy-policy' className="text-primary" target='_blank'>Privacy Policy</a>.</div>
                  <div className="text-center">
                    <button type="submit" className="default-btn" value="Send" onClick={this.handleSubmit.bind(this)}>
                      Send 
                      <span />
                    </button>
                  </div>
                </div>
                <br/>
                {this.state.isSubmitted && <div id="msgSubmit" class="alert alert-success text-center">Thank you we have received your email and will get back to you as soon as possible.</div>}
         
              </div>
          </div>
        </div>
      </div>
     </div>
 
 );
}





  
onNameChange(event) {
  if(event.target.value.match("^[a-zA-Z ]*$") != null){
    this.setState({name: event.target.value});
    let errors = this.state.errors
    errors.name = ''
    this.setState({errors});
}else{
  let errors = this.state.errors
  errors.name = 'Enter Only Character'
  this.setState({errors});
}
  }

  
  onEmailChange(event) {
    let errors = this.state.errors;
    errors.email = ValidateBussinessEmail(event.target.value)
      ? ''
      : 'Please enter your business email address';
    this.setState({
      email: event.target.value,
      errors,
    });
  }

  onFileChange(event) {
     if(event.target.files[0].type=='application/pdf' || event.target.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
      this.setState({file: event.target.files[0]})
      this.setState({isError4: ''});
    }else{
      this.setState({isError4: 'Allow only PDF and DOC File'});
    }

    if(event.target.files[0].size<=4083842){
      this.setState({file: event.target.files[0]})
      this.setState({isError4: ''});
    }else{
      this.setState({isError4: 'Maximum file size 4mb'});
    }
  }

    onNumberChange(event) {
      const re =/^[0-9\b]+$/;
      const number=event.target.value;
      let errors = this.state.errors
      if (event.target.value === "") {
        errors.number = ''
        this.setState({number: event.target.value,errors:errors})
      }
      if (event.target.value === "" || re.test(event.target.value)) {
        errors.number = ''
        this.setState({number: event.target.value,errors:errors})
      }else{
        errors.number = 'Enter Only Integer Number'
        this.setState({errors});
      }

      if (event.target.value.length > 0) {
        if(event.target.value.length >= 10){
          errors.number = '';
          this.setState({ errors });
          this.setState({ number: event.target.value, errors: errors });
        }else {
        errors.number = 'Enter Min 10 Digit Number';
        this.setState({ errors: errors });
      }
    }
    }
    onMsgChange(event) {
      let errors = this.state.errors
      if (event.target.value){
        errors.msg = ''
        this.setState({msg: event.target.value,errors:errors}) 
      }else{
        errors.msg = 'Please enter at least 15 characters.'
        this.setState({errors:errors,msg: event.target.value});
      }
   }

resetForm(){
  this.setState({name: '', email: '',number:'',msg:'',errors:{
    name:'',
    number:'',
    msg:'',
    countryName:'',
    countryCode:'',
  }})
}

  validateForm = () =>{
    let valid = true
    let errors = this.state.errors
   if(!this.state.name){
    valid = false
    errors.name = 'Name is mandatory'
     this.setState({
      errors
     })
   }
  if (!this.state.email) {
    valid = false;
    errors.email = 'Email is mandatory';
    this.setState({
      errors,
    });
  } else {
    if (!ValidateBussinessEmail(this.state.email)) {
      valid = false;
      errors.email = 'Please valid business email address';
      this.setState({
        errors,
      });
    }
  }
  if(!this.state.msg){
    valid = false
    errors.msg = 'Message is mandatory'
    this.setState({
     errors
    })
  }else if(this.state.msg && this.state.msg.length <= 15){
    valid = false
    errors.msg = 'Please enter at least 15 characters.'
    this.setState({
     errors
    })
  }
  return valid
 }
handleSubmit(e) {
  if(this.validateForm()){
  e.preventDefault();
  const formData = new FormData();
  // formData.append('filename', this.state.file);
  formData.append('name', this.state.name);
  formData.append('email', this.state.email);
  formData.append('number', this.state.number);
  formData.append('msg', this.state.msg);
  formData.append('countryname', this.state.countryName);
  formData.append('countrycode', this.state.countryCode);
  let country = localStorage.getItem("country_code");
  formData.append("country", country);
  Axios.post(getHostUrl('/api/user/sendmail'), formData, { // receive two parameter endpoint url ,form data 
  }).then((response)=>{
  console.log(response.success);
  if (response.status ==200){
    this.setState({isSubmitted: true});
      this.resetForm();
  }else{
    alert("Something went wrong.")
  }
})
}

}}



export default ContactUs;

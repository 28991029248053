import React, { useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import "./compose.scss";
import Widget from "../../components/Widget/Widget";
import Featured from "../../components/Featured/Featured";
import Chart from "../../components/Message/Message";
import axios from "axios";
import { ValidateEmail } from "../../utils/functions";
import { getHostUrl } from "../../config";

  function Compose() {
    const[message,setMessage] = useState('')
    const[subject,setSubject] = useState('')
    const[mailTo,setMailTo] = useState('')
    const[errors,setErrors] = useState({
      subject:'',
      message:'',
      mailTo:''
    })

  const onMsgChange =(event)=> {
    setMessage(event.target.value)
  }

  const onSubjectChange =(event)=> {
    setSubject(event.target.value)
  }

  const sendMailTo =(event)=> {
    setMailTo(event.target.value)
  }

 const validateForm = () =>{
    let valid = true
    let error = {}
   if(!subject){
    valid = false
    error.subject = 'Subject is mandatory'
    setErrors(error)
   }
   if(!mailTo){
    valid = false
    error.mailTo = 'Email is mandatory'
    setErrors(error)
  }else {
    if(!ValidateEmail(mailTo)){
      valid = false
      error.mailTo = 'Enter valid email'
    setErrors(error)
    }
  }
  if(!message){
    valid = false
    error.message = 'Message is mandatory'
    setErrors(error)
  }
  return valid
 }

 const resetForm =() =>{
  setErrors('')
  setSubject('')
  setMailTo('')
  setMessage('')
}

const handleSubmit =(e)=> {
  if(validateForm()){
  let form={
    subject:subject,
    message:message,
    mailTo:mailTo
  }

  axios.post(getHostUrl('/api/user/admin-mail'), form, { // receive two parameter endpoint url ,form data 
  }).then((response)=>{
  console.log(response.success);
  if (response.status ==200){
    alert("Message send successfully")
    resetForm()
  }else{
    alert("Something went wrong.")
  }
})}
}


  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="composeContainer">
        <div className="compose-formContainer">
        <span style={{paddingTop:'2px'}}>Mail to: </span>
        <input type="text" className="form-control compose-mail" value={mailTo} onChange={(e)=>sendMailTo(e)}  placeholder="Enter email address" required />
        <small className="text-mand"> {errors.mailTo}</small>
        <br/>
         <span style={{paddingTop:'2px'}}>Subject</span>
           <input type="text" className="form-control compose-name" value={subject} onChange={(e)=>onSubjectChange(e)}  placeholder="Enter subject" required />
           <small className="text-mand"> {errors.subject}</small>
           <br/>
          <span style={{paddingTop:'2px'}}>Message</span>
          <textarea value={message} required onChange={(e)=>onMsgChange(e)} placeholder="Your message" className="form-control compose-textArea" />  
          <small className="text-mand"> {errors.message}</small>   
        </div>
        <div className="composeFooter">
        <div className="text-right">
        <button type="submit" className="default-btn" onClick={()=>handleSubmit()}>
          Send 
          <span />
        </button>
      </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Compose;

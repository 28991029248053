import React from 'react';
import Axios from 'axios';
import { findIndexByKeyValue, ValidateBussinessEmail, ValidateEmail } from '../utils/functions';
import { getHostUrl } from '../config';
import countries from '../components/Countries.json'

class MailingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      organisation: '',
      email: '',
      countryName: '',
      countryCode: '',
      isSubmitted: false,
      errors: {
        first: '',
        last: '',
        org: '',
        email: '',
      },
    };
  }

  // getGeoInfo = () => {
  //   Axios.get('https://ipapi.co/json/')
  //     .then((response) => {
  //       let data = response.data;
  //       this.setState({
  //         countryName: data.country_name,
  //         countryCode: data.country_calling_code,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  getGeoInfo = async () => {
    try {
      // Step 1: Get the user's public IP address using ipify
      const ipResponse = await Axios.get("https://api.ipify.org?format=json");
      const userIP = ipResponse.data.ip;
  
      // Step 2: Fetch geolocation details using freegeoip.app
      const geoResponse = await Axios.get(`https://freegeoip.app/json/${userIP}`);
      const countryCode = geoResponse.data.country_code;
      let key = findIndexByKeyValue(countries,'code',countryCode)

      this.setState({
        countryName: key.name,
        countryCode: key.dial_code,
    });
    } catch (error) {
      console.error("Error fetching geolocation info:", error);
    }
  };

  componentDidMount() {
    this.getGeoInfo();
  }
  onFNameChange(event) {
    let errors = this.state.errors;
    if (event.target.value.match('^[a-zA-Z ]*$') != null) {
      errors.first = '';
      this.setState({ firstname: event.target.value, errors: errors });
    } else {
      errors.first = 'Enter Only Character';
      this.setState({ errors });
    }
  }

  onLNameChange(event) {
    let errors = this.state.errors;
    if (event.target.value.match('^[a-zA-Z ]*$') != null) {
      errors.last = '';
      this.setState({ lastname: event.target.value, errors: errors });
    } else {
      errors.last = 'Enter Only Character';
      this.setState({ errors });
    }
  }

  onEmailChange(event) {
    let errors = this.state.errors;
    errors.email = ValidateBussinessEmail(event.target.value)
      ? ''
      : 'Please enter your business email address';
    this.setState({
      email: event.target.value,
      errors,
    });
  }

  onOrganisationChange(event) {
    this.setState({ organisation: event.target.value });
  }

  onNumberChange(event) {
    const re = /^[0-9\b]+$/;
    let errors = this.state.errors;
    if (event.target.value === '') {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    }
    if (event.target.value === '' || re.test(event.target.value)) {
      errors.number = '';
      this.setState({ number: event.target.value, errors: errors });
    } else {
      errors.number = 'Enter Only Integer Number';
      this.setState({ errors });
    }

    if (event.target.value.length > 0) {
      if (event.target.value.length >= 10) {
        errors.number = '';
        this.setState({ errors });
        this.setState({ number: event.target.value, errors: errors });
      } else {
        errors.number = 'Enter Min 10 Digit Number';
        this.setState({ errors: errors });
      }
    }
  }

  onMsgChange(event) {
    let errors = this.state.errors;
    if (event.target.value) {
      errors.msg = '';
      this.setState({ msg: event.target.value, errors: errors });
    } else {
      errors.msg = 'Please enter at least 15 characters.';
      this.setState({ errors: errors, msg: event.target.value });
    }
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      number: '',
      msg: '',
      file: '',
      errors: {
        first: '',
        last: '',
        number: '',
        org: '',
        msg: '',
      },
    });
  }

  validateForm = () => {
    let valid = true;
    let errors = this.state.errors;
    if (!this.state.firstname) {
      valid = false;
      errors.first = 'First name is mandatory';
      this.setState({
        errors,
      });
    }
    if (!this.state.lastname) {
      valid = false;
      errors.last = 'Last name is mandatory';
      this.setState({
        errors,
      });
    }
    if (!this.state.email) {
      valid = false;
      errors.email = 'Email is mandatory';
      this.setState({
        errors,
      });
    } else {
      if (!ValidateBussinessEmail(this.state.email)) {
        valid = false;
        errors.email = 'Please valid business email address';
        this.setState({
          errors,
        });
      }
    }
    // if (!this.state.organisation) {
    //   valid = false;
    //   errors.org = 'Company name is mandatory';
    //   this.setState({
    //     errors,
    //   });
    // }

    return valid;
  };

  handleSubmit(e) {
    if (this.validateForm()) {
      e.preventDefault();
      const formData = new FormData();
      formData.append('firstname', this.state.firstname);
      formData.append('lastname', this.state.lastname);
      formData.append('email', this.state.email);
      formData.append('organisation', this.state.organisation);
      formData.append('countryname', this.state.countryName);
      formData.append('countrycode', this.state.countryCode);
      let country = localStorage.getItem("country_code");
      formData.append("country", country);
      Axios.post(getHostUrl('/api/user/subscribe-mail'), formData, {
      }).then((response)=>{
      console.log(response.success);

      if (response.status ==200){
        this.setState({isSubmitted: true});
          this.resetForm();
      }else{
        alert("Something went wrong.")
      }
      })
    }
  }

  render() {
    return (
        !this.state.isSubmitted ?
      <div>
        <div className="analysdis-form">
          <div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mail-text">
                  <span>Bussiness Email</span>
                  <span className="text-danger">*</span>
                  <input
                    type="email"
                    className="form-control mailing"
                    required
                    value={this.state.email}
                    onChange={this.onEmailChange.bind(this)}
                  />
                  <small className="text-danger">
                    {' '}
                    {this.state.errors.email}
                  </small>
                </div>
                <div className="form-group mail-text">
                  <span>First name</span>
                  <span className="text-danger">*</span>
                  <input
                    type="text"
                    className="form-control mailing"
                    required
                    value={this.state.firstname}
                    onChange={this.onFNameChange.bind(this)}
                  />
                  <small className="text-danger">
                    {' '}
                    {this.state.errors.first}
                  </small>
                </div>
                <div className="form-group mail-text">
                  <span>Last name</span>
                  <span className="text-danger">*</span>
                  <input
                    type="text"
                    className="form-control mailing"
                    required
                    value={this.state.lastname}
                    onChange={this.onLNameChange.bind(this)}
                  />
                  <small className="text-danger">
                    {' '}
                    {this.state.errors.last}
                  </small>
                </div>
                <div className="form-group mail-text">
                  <span>Company name</span>
                  <input
                    type="text"
                    className="form-control mailing"
                    required
                    value={this.state.organisation}
                    onChange={this.onOrganisationChange.bind(this)}
                  />
                  <small className="text-danger">
                    {' '}
                    {this.state.errors.org}
                  </small>
                </div>
              </div>
            </div>

            <div className="form_policy consent-text">
              Get up-to-date information on our business and services by email.
              You can unsubscribe at any time, and we will not share your data
              with any 3rd party marketing organizations. Read our{' '}
              <span>
                <a href="privacy-policy" style={{ color: 'blue' }}>
                  {' '}
                  Privacy Policy
                </a>{' '}
              </span>
            </div>
            <br />
            <div className="form_policy consent-text">
              By clicking submit below, you consent to allow Testdel UK Ltd. to
              store and process the personal information submitted above to
              provide you the content requested.
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="default-btn company-logo-color col-md-4"
                value="Send"
                onClick={this.handleSubmit.bind(this)}>
                Send
                <span />
              </button>
            </div>
          </div>
          <br />

        </div>
      </div>:
      <div id="msgSubmit" class="alert alert-success text-center">
        Thank you we have received your email and will get back to you as
        soon as possible.
      </div>
          
    );
  }

}

export default MailingForm;
